.step4 {
  display: flex;
  padding-right: 10px;

  .summaryDetailsArea {
    width: 50%;
    padding-right: 10px;
    box-sizing: border-box;
    .summaryTitle {
      font-family: Montserrat;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      border-bottom: 1px solid #adaeb637;
      padding-bottom: 20px;
    }
    .numberOfAttendees {
      display: flex;
      align-items: center;
      padding: 20px 0 20px 0;
      margin-bottom: 20px;
      border-top: 1px solid #adaeb637;
      border-bottom: 1px solid #adaeb637;
      .icon {
        margin-right: 10px;
      }
      .fieldLabel {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .sessionsDetailsArea {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .attendeeDetailCard {
        border-radius: 8px;
        width: 100%;
        padding: 20px;
        margin-bottom: 20px;
        background-color: #fafafa;

        .cardTitle {
          font-family: Montserrat;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #3e3d40;
        }

        .mainRow {
          display: flex;
          align-items: flex-start;
          width: 100%;
          .column1 {
            width: 50%;
            display: flex;
            flex-direction: column;

            .row {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              .fieldLabel {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;

                span {
                  font-weight: 700;
                  margin-right: 10px;
                }
              }
            }
          }
          .column2 {
            width: 50%;
            display: flex;
            flex-direction: column;

            .sessionsWeeksContainer {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .eachWeek {
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                .icon {
                  margin-right: 20px;
                }
                span {
                  font-size: 12px;
                  color: #adaeb6;
                  margin-left: 10px;
                }
              }
            }
          }
          @media only screen and (max-width: 550px) {
            flex-direction: column;
            .column1,
            .column2 {
              width: 100%;
            }
            .column1 {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .familyInfoCard {
      margin-top: 20px;
      border-radius: 8px;
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
      background-color: #fafafa;

      .cardTitle {
        font-family: Montserrat;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #3e3d40;
      }
      .row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .fieldLabel {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;

          span {
            font-weight: 700;
            margin-right: 10px;
          }
        }
      }
      .subRow {
        margin-left: 20px;
      }
    }
  }
  .summaryAmountArea {
    width: 50%;
    padding-left: 10px;
    box-sizing: border-box;

    .pricingBreakDownTitle {
      font-family: Montserrat;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      border-bottom: 1px solid #adaeb637;
      padding-bottom: 20px;
    }

    .pricingColumn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 10px;
      border-bottom: 1px solid #adaeb637;
      padding: 10px 0;

      .pricingRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px 0 0;

        .fieldLabel {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
        .price {
          display: flex;
          align-items: center;

          .pricingIcon {
            margin-right: 5px;
            margin-top: -8px;
            width: 20px;
            height: 20px;
          }
          .subPrice {
            font-family: Montserrat;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #3e3d40;
            display: flex;
            align-items: center;
          }
        }
      }
      .discountRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px 0 0;

        .fieldLabel {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
        .price {
          display: flex;
          align-items: center;

          .discountIcon {
            margin-right: 5px;
            margin-top: -8px;
            width: 16px;
            height: 16px;
          }
          .subPrice {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;
            color: #3e3d40;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .grandTotal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 20px 0;

      .totalText {
        color: #3e3d40;
        font-family: Montserrat;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }
      .grandTotalPrice {
        font-family: Montserrat;
        color: #3e3d40;
        line-height: 39px;
        font-weight: 700;
        font-size: 32px;
      }
    }
    .fieldLabel {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      span {
        font-weight: 700;
      }
    }
  }
  @media only screen and (max-width: 950px) {
    flex-direction: column;
    .summaryDetailsArea,
    .summaryAmountArea {
      width: 100%;
    }
  }
}
