@import url("https://fonts.googleapis.com/css?family=Oswald");

@media (min-width: 1600px) {
  .fancy span:before,
  .fancy span:after {
    width: 400px;
  }
  .modal-lg {
    width: 60%;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .fancy span:before,
  .fancy span:after {
    width: 300px;
  }
  .modal-lg {
    width: 75%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .fancy span:before,
  .fancy span:after {
    width: 250px;
  }

  .modal-lg {
    width: 90%;
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .fancy span:before,
  .fancy span:after {
    width: 200px;
  }
  .modal-lg {
    width: 90%;
  }
  #user-modal th,
  #user-modal td {
    font-size: 90%;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .fancy span {
    width: auto;
    line-height: 1;
  }
  .fancy span:before,
  .fancy span:after {
    display: none;
  }
  #user-modal th,
  #user-modal td {
    font-size: 80%;
  }
}
@media (max-width: 480px) {
  .fancy span {
    width: auto;
    line-height: 1;
  }
  .fancy span:before,
  .fancy span:after {
    display: none;
  }
  #user-modal th,
  #user-modal td {
    font-size: 70%;
  }
}

html {
  position: relative;
  height: 100%;
  overflow: auto;
}

body {
  font-family: Oswald, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wrapper {
  flex: 1 1 auto;
  padding-right: 5%;
  padding-left: 5%;
  margin-top: 66px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding-right: 0%;
    padding-left: 0%;
  }
}

body > .container {
  padding: 60px 15px 0;
}

body > .page-style > .container {
  padding-top: 0;
}

.container .text-muted {
  margin: 20px 0;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

code {
  font-size: 80%;
}

section {
  padding-top: 40px;
  padding-bottom: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

.lead {
  font-family: "Oswald", sans-serif;
}


/**** Loading spinner css start****/
.loader {
  display: flex;
  position: fixed;
  z-index: 9999;
  flex: 0 0 100%;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
}
.loading-spinner {
  display: block;
  position: absolute;
  align-self: center;
  width: 80px;
  height: 40px;
}
.loading-spinner div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #001d43;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-spinner div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.loading-spinner div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loading-spinner div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loading-spinner div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/**** Loading spinner css end ****/


#book-bar .btn-submit:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

@media (max-width: 992px) {
  #book-bar #social {
    text-align: center;
  }
}

.booking {
  display: flex;
  flex: 1 1 auto;
  max-width: 800px;
  min-height: 550px;
  transition: all 0.22s ease-out;
  margin-top: 20px;
  width: 95%;
  border: 1px solid var(--text-color-level3, rgba(77, 80, 85, 0.1));
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
}
@media only screen and (max-width: 767px) {
  .booking {
    max-width: auto;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
}
.booking.expanded-desktop {
  min-width: auto; /* was 900px */
  max-width: 1060px;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  .booking.expanded-desktop {
    margin-top: 20px;
    width: 95%;
    border: 1px solid var(--text-color-level3, rgba(77, 80, 85, 0.1));
    border-radius: 8px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
  }
}

.inner-frame {
  padding: 25px;
}
@media only screen and (max-width: 768px) {
  .inner-frame {
    /* flex: 1 1; */
    width: auto;
    transition: all 0.22s ease-out;
    padding: 0px;
  }
}

.side-bar.inner-frame {
  padding: 25px;
}
@media only screen and (max-width: 767px) {
  .side-bar.inner-frame {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: var(--container-background-color, #ffffff);
    border-bottom: 1px solid var(--text-color-level3, rgba(77, 80, 85, 0.1));
  }
}
//
//@media only screen and (max-width: 767px) {
//  #sidebar {
//    display: none;
//  }
//}
//#sidebar_mobile {
//  display: none;
//}
//@media only screen and (max-width: 767px) {
//  #sidebar_mobile {
//    display: block;
//  }
//}
.top-bar-inner-frame {
  padding: 25px;
  display: none;
}
@media only screen and (max-width: 767px) {
  .top-bar-inner-frame {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    background-color: var(--container-background-color, #ffffff);
    border-bottom: 1px solid var(--text-color-level3, rgba(77, 80, 85, 0.1));
  }
}

/* --- GG SPECIFIC ------ */
.ggSelected {
  background: #001d43 !important;
  color: #fff !important;
}
.btn-default.focus,
.btn-default:focus {
  background: auto;
  color: auto;
}

.selectableTable tr {
  cursor: pointer;
}
#testbar {
  display: none;
}
@media only screen and (max-width: 768px) {
  #testbar {
    display: block;
    /* text-align: center; */
    position: sticky;
    top: 0;
    z-index: 1;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    /* border-bottom: 1px solid rgba(77, 80, 85, 0.1);
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08); */
  }
}
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: gray;
  text-align: center;
  width: 100%;
  padding: 0;
}

#progressbar .active {
  color: #001d43;
  cursor: pointer;
}
#progressbar .active svg {
  color: #ffffff;
  background: #001d43;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 20%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f13e";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f030";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: gray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar svg {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  color: #ffffff;
  background: gray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 10px;
}

#progressbar i.active:before,
#progressbar i.active:after {
  background: #001d43;
}

.progress {
  height: 20px;
  width: 100%;
}
/* For mobile view, turn off the progress bar.  */
@media only screen and (max-width: 768px) {
  #progressbar {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .progress {
    display: none;
  }
}

.progress-bar {
  background-color: #001d43;
}

.btn-podsoft {
  background-color: #001d43 ;
  color: #fff ;
  width: 70%;
}
.btn-default {
  border-color: #ccc;
}
.btn-default:hover {
  color: white;
  background: #001d43;
}
@media only screen and (max-width: 768px) {
  .btn-podsoft {
    width: 100%;
  }
}
.btn-podsoft:hover,
.btn-podsoft:active {
  background: #001633;
  color: #fff;
}
.btn-podsoft .dropdown-menu {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .btn-group-lg.btn,
  .btn-lg {
    font-size: small;
  }
}

@media (max-width: 768px) {
  .table {
    padding: 0;
    font-size: small;
  }
}
@media (max-width: 480px) {
  .table {
    padding: 0;
    font-size: xx-small;
  }
}
@media (max-width: 440px) {
  .table {
    font-size: 7.5px;
  }
  .table td,
  .table th {
    padding: 0.5rem;
  }
}

#podsoft-calendar td {
  width: 100%;
}
.calendar-box {
  width: 100%;
  min-height: 75px;
}
.verticalLine {
  border-left: 1px solid var(--text-color-level3, rgba(21, 97, 219, 0.1));
  padding-right: 15px;
}

/* PAYMENT FORM */
.creditCardForm {
  max-width: 700px;
  overflow: hidden;
}
.creditCardForm label {
  width: 100%;
  margin-bottom: 10px;
}
.creditCardForm .heading h3 {
  text-align: center;
}
.creditCardForm .payment {
  float: left;
  font-size: 18px;
  padding: 10px 25px;
  margin-top: 20px;
  position: relative;
}
.creditCardForm .payment .form-group {
  float: left;
  margin-bottom: 15px;
}
.creditCardForm .payment .form-control {
  line-height: 40px;
  height: auto;
  padding: 0 16px;
}
.creditCardForm .owner {
  width: 63%;
  margin-right: 10px;
}
.creditCardForm .CVV {
  width: 35%;
}
.creditCardForm #card-number-field {
  width: 100%;
}
.creditCardForm #expiration-date {
  width: 49%;
}
.creditCardForm #credit_cards {
  margin-top: 25px;
  text-align: right;
}
.creditCardForm #pay-now {
  width: 100%;
  margin-top: 25px;
}
.creditCardForm .payment {
  width: 100%;
  margin-top: 3px;
  font-size: 24px;
}
.creditCardForm .payment select {
  padding: 10px;
  margin-right: 15px;
}
.creditCardForm .has-error > input {
  border: 2px solid red;
}
.creditCardForm .has-success > input {
  border: 2px solid green;
}
.transparent {
  opacity: 0.2;
}
@media (max-width: 650px) {
  .creditCardForm .owner,
  .creditCardForm .CVV,
  .creditCardForm #expiration-date,
  .creditCardForm #credit_cards {
    width: 100%;
  }
  .creditCardForm #credit_cards {
    text-align: left;
  }
}
/* for sidebar client's img */
.sidebar_img-fluid {
  max-width: 100%;
  max-height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
/* Calendar UI */
.button.direction {
  background-color: white;
}

.modal-header {
  background: #001d43;
  color: #fff;
}

.btn-primary {
  color: #fff;
  font-family: "Oswald", sans-serif;
  background-color: #444;
  border-color: transparent;
}
.btn-primary.disabled {
  border-color: transparent;
  background: #7a7a7a;
  color: #adadad;
}

.btn-primary:hover {
  border-color: transparent;
}
.btn-default:hover,
.btn-primary:hover {
  background: #001d43;
}

/*
DEFAULT: #001d43
DARK: #001633
LIGHT: #003E8F
*/
