.step2 {
  display: flex;
  width: 100%;
  @media only screen and (max-width: 1405px) {
    flex-wrap: wrap;
  }

  .attendeeForm {
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    position: relative;
    background-color: #fafafa;
    margin-right: 16px;
    min-width: 255px;
    max-width: 420px;
    width: 100%;
    @media only screen and (max-width: 500px) {
      width: 100% !important;
      min-width: calc(100% - 16px);
      max-width: calc(100% - 16px);
    }

    .validatedIcon{
      position: absolute;
      right: 10px;
      top: 10px;
      transition: .2s ease-in-out;
    }

    .attendeeFormTitle {
      font-family: Montserrat;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #3e3d40;
    }

    .attendeeFormRow {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .inputFieldColumns {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;
        margin-right: 30px;
        width: 100%;

        .inputFieldArea {
          width: 100%;
          max-width: 400px;
          position: relative;
          margin-bottom: 30px;

          .fieldLabel {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 8px;
            width: 100%;
          }

          .selectInputFieldArea {
            border-radius: 6px;
            padding: 12px 24px;
            border: 1px solid #adaeb6;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
            position: relative;
            background-color: #ffffff;

            .arrowIcon {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-40%) rotate(90deg);
            }
          }
          .dropdownContainer {
            position: absolute;
            width: 100%;
            top: 80px;
            left: 0px;
            z-index: 10;
            border-radius: 6px;
            padding: 10px;
            box-shadow: 4px 4px 16px #adaeb649;
            background-color: #ffffff;
            max-height: 200px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
            &::-webkit-scrollbar-track {
              border-radius: 10px;
              background: lightgray;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: grey;
            }
            &::-webkit-scrollbar-thumb:hover {
              transform: scaleX(0.9);
              background: grey;
            }
            &::-webkit-scrollbar-corner {
              background: lightgray;
            }

            .dropdownOption {
              font-weight: 500;
              font-size: 14px;
              padding: 12px 24px;
              border-radius: 6px;
              cursor: pointer;

              &:hover {
                background-color: #adaeb638;
              }
            }
          }
          @media only screen and (max-width: 950px) {
            width: 100%;
          }
        }
        .disabled {
          opacity: 0.7;
          cursor: not-allowed;
          .selectInputFieldArea {
            cursor: not-allowed;
          }
        }
        @media only screen and (max-width: 520px) {
          width: 100%;
          .inputFieldArea {
            width: 100%;
          }
        }
      }
      .sessionWeeksColumn {
        display: flex;
        flex-direction: column;
        width: 100%;

        .fieldLabel {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 20px;
          width: 100%;
        }
        .sessionsWeeksContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .sessionRow {
            display: flex;
            align-items: center;
            margin-right: 40px;

            .sessionWeek {
              display: flex;
              align-items: center;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 20px;

              .checkboxArea {
                position: relative;

                .checkmark {
                  position: relative;
                  cursor: pointer;
                  padding: 0;

                  &::before {
                    content: "";
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: text-top;
                    width: 20px;
                    height: 20px;
                    background: #ffffff;
                    border-radius: 4px;
                    border: 1px solid #adaeb6ac;
                    transition: 0.2s all ease-in-out;
                  }

                  &::after {
                    content: "";
                    position: absolute;
                  }
                }
                .checkbox {
                  cursor: pointer;
                  opacity: 0.5;
                  display: none;

                  &:checked ~ .checkmark::before {
                    background: #0067fe;
                    border: 1px solid #0067fe;
                    content: "";
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: text-top;
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    transition: 0.2s all ease-in-out;
                  }

                  &:checked ~ .checkmark::after {
                    left: 5px;
                    top: 10px;
                    background: #ffffff;
                    width: 2px;
                    height: 2px;
                    box-shadow:
                      2px 0 0 white,
                      4px 0 0 white,
                      4px -2px 0 white,
                      4px -4px 0 white,
                      4px -6px 0 white,
                      4px -8px 0 white;
                    transform: rotate(45deg);
                  }
                }
              }
              .sessionColumn {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 10px 0 0 10px;
                width: 116px;

                .date {
                  margin-left: 0;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  color: #adaeb6;
                }
              }
              .pricing {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #3e3d40;
                margin-left: 20px;
              }
            }
          }
        }
        @media only screen and (max-width: 520px) {
          width: 100%;
          .fieldLabel {
            width: 100%;
          }
        }
      }
      @media only screen and (max-width: 675px) {
        flex-direction: column;
      }
    }
  }
}
