.stepsIndicatorContainer {
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid #adaeb6;

  .arrowIcon {
    margin: 0 10px;
  }

  .step {
    display: flex;
    align-items: center;
    color: #adaeb6;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    .stepNumber {
      background-color: #adaeb6;
      color: #ffffff;
      height: 24px;
      width: 24px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    .selectedText {
      color: #3e3d40;
    }
    .selectedStepNumber {
      background-color: #0067fe;
    }

    @media only screen and (max-width: 675px) {
      .stepName{
        display: none;
      }
    }
  }

  .selectedStep {
    color: #3e3d40;
    .stepNumber {
      background-color: #0067fe;
    }
  }
}
