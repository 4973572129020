@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// pages
@import "./pages/libertyLake.scss";

// components
@import "./components/stepsIndicator.scss";
@import "./components/footer.scss";
@import "./components/step1.scss";
@import "./components/step2.scss";
@import "./components/step3.scss";
@import "./components/step4.scss";
