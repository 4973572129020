.step1 {
  position: relative;
  padding-right: 10px;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  background-color: #fafafa;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: lightgray;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: grey;
  }
  &::-webkit-scrollbar-thumb:hover {
    transform: scaleX(0.9);
    background: grey;
  }
  &::-webkit-scrollbar-corner {
    background: lightgray;
  }

  .stepTitle {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #3e3d40;
  }

  .row {
    display: flex;
    align-items: center;

    .inputFieldArea {
      width: 400px;
      position: relative;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .fieldLabel {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 8px;
      }

      .inputField {
        border-radius: 6px;
        padding: 12px 18px;
        border: 1px solid #adaeb6;
        font-weight: 600;
        font-size: 14px;
        outline: none;
        width: 100%;
        background-color: #fff;

        &::placeholder {
          color: #adaeb6;
        }
      }
    }

    @media only screen and (max-width: 885px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .inputFieldArea {
    width: 377px;
    position: relative;

    .fieldLabel {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 8px;
    }

    .selectInputFieldArea {
      border-radius: 6px;
      padding: 12px 24px;
      border: 1px solid #adaeb6;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      background-color: #fff;

      position: relative;

      .arrowIcon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-40%) rotate(90deg);
      }
    }
    .dropdownContainer {
      background-color: #ffffff;
      position: absolute;
      width: 300px;
      top: 80px;
      left: 0;
      z-index: 10;
      border-radius: 6px;
      padding: 10px;

      box-shadow: 4px 4px 16px #adaeb649;

      .dropdownOption {
        font-weight: 500;
        font-size: 14px;
        padding: 12px 24px;
        border-radius: 6px;
        cursor: pointer;

        &:hover {
          background-color: #adaeb638;
        }
      }
    }
  }
  @media only screen and (max-width: 477px) {
    .inputFieldArea{
      width: 100%;
    }
  }
}
