.libertyLake {
  font-family: Montserrat, Roboto !important;
  max-width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  position: relative;

  .imageArea {
    width: 500px;
    height: 100%;
    background-color: grey;
  }
  .contentArea {
    width: 100%;
    height: 100%;
    padding: 30px 30px 0 30px;
    box-sizing: border-box;
    position: relative;

    .title {
      color: #3e3d40;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      .logo {
        height: 46px;
        margin-right: 20px;
      }
    }

    .stepsContainer {
      padding: 20px 0;
      overflow-y: auto;
      height: 70vh;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: lightgray;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: grey;
      }
      &::-webkit-scrollbar-thumb:hover {
        transform: scaleX(0.9);
        background: grey;
      }
      &::-webkit-scrollbar-corner {
        background: lightgray;
      }

      .backDrop {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 9;
      }
    }

    @media only screen and (max-width: 477px) {
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .loadingPanel {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #3e3d401a;
    backdrop-filter: blur(3px);
    z-index: 12;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .lds-ripple {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-ripple div {
      position: absolute;
      border: 4px solid #0067fe;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
      animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
      0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
      }
      4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
      }
      5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
      }
    }
  }
}
