.footerContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 80px;
  width: 100%;
  border-top: 1px solid #adaeb6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #ffffff;

  .buttonContainer {
    display: flex;
    align-items: center;
    button {
      padding: 12px 24px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19.5px;
      outline: none;
      border-radius: 6px;
      transition: all .2s ease-in-out;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .disabledButton {
      opacity: 0.7;
      cursor: not-allowed;
    }

    .back {
      border: 1px solid #0067fe;
      color: #0067fe;
      background-color: #ffffff;
      margin-right: 10px;
    }
    .next {
      border: none;
      color: #ffffff;
      background-color: #0067fe;
      margin-left: 10px;
    }
    .hide{
      display: none;
    }
  }
}
