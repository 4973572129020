.step3 {
  padding-bottom: 100px;
  background-color: #fafafa;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  padding-right: 10px;

  .formArea {
    border-radius: 8px;
    width: 50%;
    margin-bottom: 20px;
    margin-right: 10px;
    padding-right: 10px;

    .formTitle {
      font-family: Montserrat;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #3e3d40;
      margin-bottom: 30px;
    }

    .transportationMeans {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 8px;
    }

    .amPmRow {
      display: flex;
      align-items: center;
      width: 800px;

      .inputFieldArea {
        margin-right: 20px;
      }

      @media only screen and (max-width: 875px) {
        flex-direction: column;
        align-items: flex-start;
        .inputFieldArea {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: 500px) {
        width: 100%;
      }
    }

    .row {
      display: flex;
      align-items: center;

      .inputFieldArea {
        width: 400px;
        position: relative;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .fieldLabel {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 8px;
        }

        .inputField {
          border-radius: 6px;
          padding: 12px 18px;
          border: 1px solid #adaeb6;
          font-weight: 600;
          font-size: 14px;
          outline: none;
          width: 100%;
          background-color: #fff;
        }
      }
    }

    .inputFieldArea {
      width: 376px;
      position: relative;
      margin-bottom: 30px;

      .fieldLabel {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 8px;
      }

      .selectInputFieldArea {
        border-radius: 6px;
        padding: 12px 24px;
        border: 1px solid #adaeb6;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        background-color: #fff;

        .arrowIcon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-40%) rotate(90deg);
        }
      }
      .helpText {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: grey;
      }
      .dropdownContainer {
        background-color: #ffffff;
        max-height: 200px;
        overflow-y: auto;
        position: absolute;
        width: 350px;
        top: 80px;
        left: 0;
        z-index: 10;
        border-radius: 6px;
        padding: 10px;
        box-shadow: 4px 4px 16px #adaeb649;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: lightgray;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: grey;
        }
        &::-webkit-scrollbar-thumb:hover {
          transform: scaleX(0.9);
          background: grey;
        }
        &::-webkit-scrollbar-corner {
          background: lightgray;
        }

        .dropdownOption {
          font-weight: 500;
          font-size: 14px;
          padding: 12px 24px;
          border-radius: 6px;
          cursor: pointer;

          &:hover {
            background-color: #adaeb638;
          }
        }
      }
      @media only screen and (max-width: 500px) {
        width: 100%;
      }
    }

    .earlyPayDiscountArea {
      display: flex;
      align-items: center;

      .fieldLabel {
        margin-right: 20px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
      .checkboxArea {
        position: relative;

        .checkmark {
          position: relative;
          cursor: pointer;
          padding: 0;

          &::before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #adaeb6ac;
            transition: 0.2s all ease-in-out;
          }

          &::after {
            content: "";
            position: absolute;
          }
        }
        .checkbox {
          cursor: pointer;
          opacity: 0.5;
          display: none;

          &:checked ~ .checkmark::before {
            background: #0067fe;
            border: 1px solid #0067fe;
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            transition: 0.2s all ease-in-out;
          }

          &:checked ~ .checkmark::after {
            left: 5px;
            top: 10px;
            background: #ffffff;
            width: 2px;
            height: 2px;
            box-shadow:
              2px 0 0 white,
              4px 0 0 white,
              4px -2px 0 white,
              4px -4px 0 white,
              4px -6px 0 white,
              4px -8px 0 white;
            transform: rotate(45deg);
          }
        }
      }
    }
    .helpText {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: grey;
    }
  }
  .sessionWeeksColumn {
    display: flex;
    flex-direction: column;
    margin: 58px 0 10px 0;

    .fieldLabel {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 20px;
    }
    .sessionsWeeksContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 36px;
      .sessionRow {
        display: flex;
        align-items: center;
        margin-right: 40px;

        .sessionWeek {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 20px;

          .checkboxArea {
            position: relative;

            .checkmark {
              position: relative;
              cursor: pointer;
              padding: 0;

              &::before {
                content: "";
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                background: #ffffff;
                border-radius: 4px;
                border: 1px solid #adaeb6ac;
                transition: 0.2s all ease-in-out;
              }

              &::after {
                content: "";
                position: absolute;
              }
            }
            .checkbox {
              cursor: pointer;
              opacity: 0.5;
              display: none;

              &:checked ~ .checkmark::before {
                background: #0067fe;
                border: 1px solid #0067fe;
                content: "";
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                border-radius: 4px;
                transition: 0.2s all ease-in-out;
              }

              &:checked ~ .checkmark::after {
                left: 5px;
                top: 10px;
                background: #ffffff;
                width: 2px;
                height: 2px;
                box-shadow:
                  2px 0 0 white,
                  4px 0 0 white,
                  4px -2px 0 white,
                  4px -4px 0 white,
                  4px -6px 0 white,
                  4px -8px 0 white;
                transform: rotate(45deg);
              }
            }
          }
          .sessionColumn {
            width: 116px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 10px 0 0 10px;

            .date {
              margin-left: 0;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              color: #adaeb6;
            }
          }
          .pricing {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #3e3d40;
            margin-left: 20px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 875px) {
    flex-direction: column;
    .sessionWeeksColumn,
    .formArea {
      width: 100%;
    }
  }
}
